<template lang="pug">
	div
		v-app-bar(
			color="blue-700"
			dark
			:clipped-left="$vuetify.breakpoint.lgAndUp"
			app
		)
			v-app-bar-nav-icon(@click.stop="$emit('onSwitchDrawerEvent')")
			v-toolbar-title(class="ml-5") 
				v-btn(text   :to="{name:'home'}")  
					span(class="white--text font-weight-bold") 
				//- .flex-grow-1
			v-spacer
			label(class="white--text mr-2") {{userArea}}
			//-label(class="white--text mr-2") Menu
			v-btn( text icon color="white" :to="{name:'panel'}" @click="handleClickDashboard")
				v-icon mdi-view-dashboard
			v-btn( text  icon color="white" class="mx-1" @click="logout")
				v-icon mdi-logout
</template>

<script>
	import { mapGetters } from 'vuex';
	import DataLayersMixin from '@/mixins/DataLayersMixin'

	export default {
		name: 'DashboardCoreAppBar',
		mixins: [DataLayersMixin],
		computed: {
        ...mapGetters('session', ['userArea']),
		},
		methods: {
			handleClickDashboard() {
				switch(this.$route.name) {
					case 'recomendador_cat':
						this.dataLayerClickBtnPanelFromRecCAT()
						break;
					case 'panel':
						this.dataLayerClickBtnPanel()
						break;
				}
			},
			logoutDatalayers() {
				switch(this.$route.name) {
					case 'recomendador_cat':
						this.dataLayerClickCerrarSessionFromRecCAT()
						break;
					case 'panel':
						this.dataLayerClickBtnCerrarSession();
						break;
				}
			},
			logout:function() {
				this.logoutDatalayers()
				this.$store.dispatch('session/deleteSession');
				this.$router.push({name: 'login'})
			}
		}

	}
</script>
